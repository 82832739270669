import { useIsMobileDevice } from "hooks";
import { useTranslation } from 'react-i18next';
import './style.scss';

const ThankYou = ({ message }) => {
  const { t } = useTranslation();  // Access translations using useTranslation
  const isMobile = useIsMobileDevice();

  return (
    <div className={`message-container thankyou-opacity flex flex-col items-center justify-center`}>
      <span className='heading-emoji'>
        🎉
      </span>
      <span className="heading mb-18">
        {t('thank_you.thank_you_message')}
      </span>
      <span className={`sub-heading mb-5 font-medium ${isMobile ? "text-center" : "align-center"}`}>
        {message ?? t('thank_you.feedback_message')}
      </span>
    </div>
  );
};

export default ThankYou;