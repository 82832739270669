import { Api } from '../config/request';

const endpoints = {
  syncLinkData(linkId = '') {
    return Api.get(`/api/session/v1/${linkId}`);
  },
  quitUnberryGamePlay(linkId = null, sendEmail = false, disqualifyData = {}) {
    return Api.put(
      `/api/session/v1/link/${linkId}/mark-complete?sendEmail=${sendEmail}`, disqualifyData);
  },
  getGameplayConfig(idType, id) { // idType -> linkId or positionId
    return Api.get(`/api/position/v1/position-config?${idType}=${id}`, {
      'Content-Type': 'json',
    });
  },
  updateUserProfile(userId, data) {
    return Api.put(`/api/user/v1/${userId}`, data);
  },
  updateGamePlayStatus(payload) {
    return Api.put(`/api/session/v1/start-gameplay`, payload);
  },
  getListOfGames(linkId = null) {
    return Api.get(`/api/game/v1/${linkId}/get-games`);
  },
  getGameLevelData(gameId, linkId) {
    return Api.get(`/api/game/v1/${gameId}/link/${linkId}`);
  },
  saveGameLevelData(gameId, linkId, gameLevelData) {
    return Api.post(`/api/session/v1/${gameId}/link/${linkId}`, gameLevelData);
  },
  getQuizData(quizId, linkId) {
    return Api.get(`/api/quiz/v1/${quizId}/questions?linkId=${linkId}`);
  },
  submitQuizAnswer(linkId, quizId, questionId, payload) {
    return Api.post(
      `/api/session/v1/${linkId}/${quizId}/${questionId}/submit-answer`,
      payload
    );
  },
  markQuizComplete(quizId, payload) {
    return Api.post(`/api/quiz/v1/${quizId}/mark-complete`, payload);
  },
  getCodingProblem(problemId, linkId) {
    return Api.get(
      `/api/coding-tool/v1/problems/${problemId}?linkId=${linkId}`
    );
  },
  compileCodingAnswer(payload) {
    return Api.post(`/api/coding-tool/v1/compile`, payload);
  },
  getCodingSession(problemId, linkId) {
    return Api.get(
      `/api/coding-tool/v1/sessions?linkId=${linkId}&problemId=${problemId}`
    );
  },
  saveCodingSession(payload) {
    return Api.post(`/api/coding-tool/v1/sessions`, payload);
  },
  submitCodingAnswer(payload) {
    return Api.post(`/api/coding-tool/v1/submit`, payload);
  },
  uploadAudioAnswer(linkId, questionId, payload) {
    return Api.post(
      `/api/media/v1/${linkId}/${questionId}/audio-answer`,
      payload,
      {
        isFormData: true,
      }
    );
  },
  completeChat(promptId, payload) {
    return Api.post(`/api/chat-analysis/v1/${promptId}/complete-chat`, payload)
  },
  createGameplayModuleMasterSession(payload) {
    return Api.post(`/api/master-session/v1/`, payload);
  },
  getLinkPositionConfig(linkId) {
    return Api.get(`/api/link/v1/get-link-position-config/${linkId}`)
  },
  markGameComplete(linkId, payload) {
    return Api.post(`/api/session/v1/${linkId}/mark-game-complete`, payload)
  },
};

export default endpoints;
