/* eslint-disable no-undef */
import Toast from '../../components/dumb/Toast';
import { storage } from '../../services/config/storage';
import Config from './apiUrl';
import * as Sentry from '@sentry/react';
import { objectToFormData } from 'utils/helper';

const _AuthHeaders = () => {
  const token = storage.get.authToken();
  return {
    Authorization: `${token}`,
    'Content-Type': 'application/json',
  };
};

const connectionHandler = () => {
  return navigator.onLine ? true : false;
};

const errHandler = (res) => {
  Sentry.setExtra('response', res);
  Sentry.captureException(res?.message ? new Error(res.message) : res);
  if (res.status === 401) {
    // call for refresh token
    // refreshAuthToken();
    // > logout
  } else if (res.status === 403) {
    // Exlcude on when called with certain customErrorCode
    // 2104 - Call to resubmit code after coding session expires.
    if (res.customErrorCode === 2104) return;
    // 403 returned for archived assessment - Show error to user on otp, login screen instead
    if (res.url.includes("user/v1/otp") || res.url.includes("user/v1/login") || res.url.includes("user/v1/third-party/login")) return;
    const linkId = storage.get.linkId();
    storage.destroy.all();
    storage.set.tokenExpiry(true);
    Toast('error', 'Your session has expired, please, re-login.');
    setTimeout(() => {
      window.location.href = window.location.origin + `/home/${linkId}`;
    }, 4000);
  } else if (res.status === 404) {
    if (res.customErrorCode === 2004) {
      Toast('error', res.message);
    }
  }
  // Commenting it out because no execution is getting carried out.
  // else if (res.status === 400) {
  //   const contentType = res.headers?.get('content-type');
  //   if (contentType && contentType.indexOf('application/json') !== -1) {
  //     res.json().then((res) => {
  //       if (
  //         res.error &&
  //         res.message &&
  //         (typeof res.message === 'string' || res.message instanceof String)
  //       ) {
  //         // Toast('error', `Opps! Error.. ${res.message}`);
  //       } else {
  //         // Toast('error', `Opps! Error`);
  //       }
  //     });
  //   } else {
  //     if(res.text) {
  //       res.text().then((res) => {
  //         // Toast('error', `Opps! Error. ${res.error ? res.message : ''}`);
  //       })
  //     }
  //   }
  // }
};

export const get = async (url, headers) => {
  if (!headers) {
    const data = _AuthHeaders();
    headers = data;
  }

  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, { headers })
        .then(async (res) => {
          let resData;
          const contentType = res.headers?.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            resData = await res.json();
          } else if (contentType && contentType.indexOf("image") !== -1) {
            return res?.arrayBuffer()
          } else {
            resData = await res.text();
          }
          if (res.status !== 200) {
            const error = { ...resData, status: res.status, url };
            throw error;
          }
          return resData;
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if(err.url.includes("ref-image")) resolve({ status: 404 }) 
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const post = async (url, data = {}, { headers, isFormData }) => {

  if (!headers) {
    const data = _AuthHeaders();
    headers = data;
  }

  if (isFormData) {
    data = objectToFormData(data);
    delete headers['Content-Type'];
  } else {
    data = JSON.stringify(data);
  }

  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: 'POST',
        headers,
        body: data,
      })
        .then(async (res) => {
          let resData;
          const contentType = res.headers?.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            resData = await res.json();
          } else {
            resData = await res.text();
          }
          if (res.status !== 200) {
            const error = {
              ...resData,
              status: res.status,
              url,
              data: JSON.stringify(data),
            };
            throw error;
          }
          return resData;
        })
        .then((res) => {
          if (res.error) {
            reject(
              res?.errorData
                ? res.errorData.message
                : res.message
                  ? res.message
                  : res.error
            );
          } else {
            resolve(res.result ? res.result : res);
          }
        })
        .catch((err) => {
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const put = async (url, data = {}, headers) => {
  if (!headers) {
    const data = _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: 'PUT',
        headers,
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          let resData;
          const contentType = res.headers?.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            resData = await res.json();
          } else {
            resData = await res.text();
          }
          if (res.status !== 200) {
            const error = {
              ...resData,
              status: res.status,
              url,
              data: JSON.stringify(data),
            };
            throw error;
          }
          return resData;
        })
        .then((res) => {
          if (res.error) {
            reject(res.message ? res.message : res.error);
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          window.err = err;
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const externalPut = async (url, data = {}, headers) => {
  if (!headers) {
    const data = _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'PUT',
        headers,
        body: data,
      })
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          window.err = err;
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const patch = async (url, data = {}, headers) => {
  if (!headers) {
    const data = _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (res.status !== 200) {
            const error = { ...data, status: res.status, url };
            throw error;
          }
          const contentType = res.headers?.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return res.json();
          } else {
            return res.text();
          }
        })
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res.result);
          }
        })
        .catch((err) => {
          window.err = err;
          errHandler(err);
          reject(err);
        });
    });
  }
};

export const deleteCall = async (url, headers) => {
  if (!headers) {
    const data = _AuthHeaders();
    headers = data;
  }
  if (connectionHandler()) {
    return new Promise(async (resolve, reject) => {
      // await checkExpiry();
      fetch(url, {
        method: 'DELETE',
        headers,
      })
        .then((res) => (res && res.json()) || {})
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }
};

export const Api = {
  get: (url, headers = _AuthHeaders(), noBaseUrl) =>
    get(`${noBaseUrl ? url : Config.API_URL + url}`, headers),

  post: (
    url,
    data,
    { headers = _AuthHeaders(), noBaseUrl = false, isFormData = false } = {}
  ) =>
    post(`${noBaseUrl ? url : Config.API_URL + url}`, data, {
      headers,
      isFormData,
    }),

  put: (url, data, headers = _AuthHeaders()) =>
    put(`${Config.API_URL}${url}`, data, headers),

  patch: (url, data, headers = _AuthHeaders()) =>
    patch(`${Config.API_URL}${url}`, data, headers),

  deleteCall: (url, headers = _AuthHeaders()) =>
    deleteCall(`${Config.API_URL}${url}`, headers),

  externalPutCall: (url, data, headers = _AuthHeaders()) =>
    externalPut(url, data, headers),

  url: () => Config.API_URL,
};
