import { useCallback, useEffect, useState } from "react"
import moment from "moment"
import ProctoringApi from "services/apis/proctoring"
import { storage } from "services/config/storage"
import { useGameplayConfig } from "store"
import { PERMISSION_ERRORS } from "constants/globalConstants"
import { classifyMediaDeviceError } from "utils/helper"

export const useVideoRecorder = () => {
    const [recorder, setRecorder] = useState(null)
    const [recorderState, setRecorderState] = useState("")
    const [isPermissionModalVisible, setIsPermissionModalVisible] = useState("");

    const { isVideoProctored } = useGameplayConfig();
    const mimeType = MediaRecorder.isTypeSupported("video/webm") ? "video/webm" : "video/mp4"

    useEffect(() => {
        (async () => {
            try {
                if (isVideoProctored) {
                    RECORDING_CONST.stream = await navigator.mediaDevices.getUserMedia({
                        video: {
                            deviceId: storage.get.webCam(),  // use the same device for video that was used for initial image 
                            frameRate: { ideal: 5, max: 5 }
                        },
                        audio: {
                            deviceId: storage.get.micDevice()
                        },
                    })
                    const mediaRecorder = new MediaRecorder(RECORDING_CONST.stream, {
                        mimeType,
                        videoBitsPerSecond: RECORDING_CONST.videoBitsPerSecond,
                        audioBitsPerSecond: RECORDING_CONST.audioBitsPerSecond
                    })
                    setRecorder(mediaRecorder)
                }
            } catch (err) {
                console.error("Error in camera permission for video", err)
                const classifiedError = classifyMediaDeviceError(err?.toString())
                setIsPermissionModalVisible(classifiedError)
            }
        })()
        return () => {
            stopRecording()
            setRecorder(null)
            RECORDING_CONST.stream = null
        }
    }, [isVideoProctored])

    const handleDataAvailable = useCallback(async (event) => {
        const currentTime = new Date()
        /**
         * TODO :: BE support for webm
         * 
         * Save fileName as mp4 - Regardless mimeType
         * Webm not supported yet
         *  */

        // Post sleep and power-saving mode -> readyState === ended (may get set, not necessary)
        // Ref - https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack || https://developer.mozilla.org/en-US/docs/Web/API/MediaStream/getVideoTracks
        // Check and let progress only if mediaTrack (camera) readyState === "live"

        const cameraFeed = RECORDING_CONST?.stream?.getVideoTracks()
        const checkCameraReadyState = cameraFeed.length > 0 && cameraFeed[0]?.readyState === "live"
        if (!checkCameraReadyState) {
            // Trigger permission modal -> Same as no internet type
            setIsPermissionModalVisible(PERMISSION_ERRORS.CAMERA_FEED);
            return;
        }
        const fileName = `video|${moment(currentTime).format("DD-MM-YYYY")}|${moment(currentTime).format("HH:mm:ss")}.${'mp4'}`
        const file = new File([event.data], fileName, {
            type: mimeType,
        })
        let currentVideoOrder = Number(storage.get.videoOrderCount())
        let videoSessionId = storage.get.videoSessionId()
        storage.set.videoOrderCount(currentVideoOrder + 5)
        try {
            await ProctoringApi.sendUserVideo({
                file,
                fileName,
                startTime: currentVideoOrder,
                endTime: currentVideoOrder + 5,
                duration: 5,
                sessionId: videoSessionId,
                type: "video",
            })
        } catch (err) {
            console.error(err.message)
        }

    }, [recorder])

    useEffect(() => {
        if (recorder) {
            startRecording()
            recorder.addEventListener("dataavailable", handleDataAvailable)
        }
        return () => recorder?.removeEventListener("dataavailable", handleDataAvailable)
    }, [recorder])

    useEffect(() => {
        let timeout;
        try {
            if (recorderState === "recording") {
                timeout = setTimeout(() => {
                    recorder?.stop()
                    setRecorderState(recorder?.state)
                }, RECORDING_CONST.recordingInterval)
            } else if (recorderState === "inactive") {
                recorder?.start()
                setRecorderState(recorder?.state)
            }
        } catch {
            setIsPermissionModalVisible(true)
        }

        return () => clearTimeout(timeout)
    }, [recorderState])

    const startRecording = () => {
        try {
            if (recorder?.state !== "recording") {
                recorder?.start()
                setRecorderState(recorder?.state)
                clearTimeout(RECORDING_CONST.initialTimeout)
                RECORDING_CONST.initialTimeout = setTimeout(() => {
                    if (recorder?.state !== "inactive") {
                        recorder?.stop()
                        setRecorderState(recorder?.state)
                    }
                }, RECORDING_CONST.recordingInterval)
            }
        } catch {
            setIsPermissionModalVisible(true)
        }

    }

    const stopRecording = () => {
        try {
            if (recorder?.state !== "inactive") {
                recorder?.stop()
                setRecorderState("")
                clearTimeout(RECORDING_CONST.initialTimeout)
                RECORDING_CONST.stream?.getTracks()?.forEach(function (track) {
                    track?.stop()
                })
            }
        } catch {
            setIsPermissionModalVisible(true)
        }

    }

    return { isPermissionModalVisible }
}


const RECORDING_CONST = {
    stream: null,
    initialTimeout: "",
    recordingInterval: 6000,
    duration: 5,
    videoBitsPerSecond: 1000000,
    audioBitsPerSecond: 50000

}