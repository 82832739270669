
const apnaOrgInstructionsList = [
  {
    sectionHeader: "Key Instructions",
    sectionContent: [
      {
        title: "1. Complete All Questions",
        points: [
          "You cannot skip a question. Each question must be answered to proceed to the next.",
          "Once an answer is submitted, it is final. You cannot revisit or change your response."
        ]
      },
      {
        title: "2. Stay Focused",
        points: [
          "Attempt the assessment in one sitting. The timer will not pause once the test begins.",
          "Ensure a calm, distraction-free environment for the duration of the test."
        ]
      },
      {
        title: "3. Technical Essentials",
        points: [
          "Use a desktop or laptop with a functioning webcam and microphone for the best experience. Mobile devices with front cameras are acceptable but not recommended.",
          "Ensure your mic and camera are enabled and accessible to the application.",
          "Check your internet connection for stability before starting the test."
        ]
      },
      {
        title: "4. Time Management",
        points: ["The assessment is time-bound. Keep track of the timer."]
      },
      {
        title: "5. Proctoring Guidelines",
        points: [
          "Full-screen mode is mandatory. Any action that disrupts it will be treated as a violation."
        ]
      }
    ]
  },
  {
    sectionHeader: "Additional Information",
    sectionContent: [
      {
        title: "There is no negative marking.",
      },
      {
        title: "Ensure you complete the test within the specified assessment window. Late attempts will not be allowed.",
      },
      {
        title: "For any issues or questions during the test, please reach out to compete@apna.co",
      },
      {
        title: "Unfair practices will result in immediate disqualification.",
      },
    ]
  }
];

const KeyInstructions = ({ instructions }) => (
  <div>
    <p className="size-24 font-medium color-primary mb-10">{instructions.sectionHeader}</p>
    <ul className="color-primary font-medium pl-0 mb-40 rules-ul-list">
      {instructions.sectionContent.map((rule, index) => (
        <li key={index} className="ml-0">
          <span className="size-18 font-medium color-primary mb-10">{rule.title}</span>
          <ul className="color-primary font-medium">
            {rule.points?.map((point, pointIndex) => (
              <li key={pointIndex} className="ml-0 mb-10">
                <span className="size-14 font-light opacity-70 color-primary">{point}</span>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </div>
);

const AdditionalInformation = ({ information }) => (
  <div>
    <p className="size-24 font-medium color-primary mb-10">{information.sectionHeader}</p>
    <ul className="color-primary font-medium pl-12 mb-40">
      {information.sectionContent.map((item, index) => (
        <li key={index} className="ml-0 mb-10">
          <span className="size-14 opacity-70 font-medium color-primary">{item.title}</span>
        </li>
      ))}
    </ul>
  </div>
);

const ApnaOrgInstructions = () => {
  return (
    <div>
      <KeyInstructions instructions={apnaOrgInstructionsList[0]} />
      <AdditionalInformation information={apnaOrgInstructionsList[1]} />
      <p className="size-18 font-medium color-primary mb-20">Good luck with your assessment!</p>
    </div>
  );
};

export default ApnaOrgInstructions;
