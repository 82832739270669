import { countries } from "countries-list";
import { GrinEmoji, NeutralEmoji, ScreamEmoji, SmileEmoji, SpiralEmoji } from "assets/icons";

export const ORG_SET_EVENT = "ORG_SET_EVENT";

export const DEFAULT_COMPANY_NAME = "unberry";

// TODO - Temporary fix against orgId
export const ASSESSMENT_SECTION_ORG_IDS = [
  // Dev
  // "631310b7-1893-4a26-919f-ae9433d0e72e",
  // "55d87803-f999-4329-af74-f6d3dbe56f61", // DREAMT
  // Prod
  "754f36c4-e6e8-49d3-9a4b-209b39c3f5b5"
]

export const GODREJ_SECTION_IDS = [
  // Dev
  "216963a9-3a6f-49cd-bd08-853d2a370806",
  // Prod
  // "4f2faf6e-2059-4a3c-ac06-d65795222af8"
  "cbe41085-6df7-4dc6-b3d8-9a6298874161",
  "8e83e16f-d5df-4817-a090-154e5071b09a",
  "5a13fe9f-1842-4a2f-b886-e2a15443db6c",
]

export const APNA_ORG_SECTION_IDS = [
  //dev
  "77a0fbb1-2457-4421-b83a-3d7271da80de",
  //Prod
  "19d3d6b8-42e2-4c69-99f5-c0c2867ed47e"
]

export const PRE_WORKSHOP_GODREJ = "8e83e16f-d5df-4817-a090-154e5071b09a"
export const POST_WORKSHOP_GODREJ = "5a13fe9f-1842-4a2f-b886-e2a15443db6c"

export const DEFAULT_FC_TAG_LIST = ["gamecenter"];

export const COMPANY_FC_TAG_LIST = {
  DELHIVERY: "delhivery"
};

export const ORGANIZATIONS_NAME = {
  SPOTLIGHT: "Spotlight"
}

export const COUNTRY_DATA = Object.keys(countries).map((code) => {
  return ({
    ...countries[code],
    countryAbrev: code
  })
});

export const DEFAULT_COUNTRY_DATA = { ...countries["IN"], countryAbrev: "IN" };

export const LOGIN_TYPE = {
  EMAIL: "email",
  PHONE: "phone",
  THIRD_PARTY: "thirdParty"
};

export const feedbackFeelingsArray = [
  {
    key: "mind_blown",
    label: "Mind Blown",
    icon: SmileEmoji
  },
  {
    key: "happy",
    label: "Happy",
    icon: GrinEmoji
  },
  {
    key: "neutral",
    label: "Neutral",
    icon: NeutralEmoji
  },
  {
    key: "exhausted",
    label: "Exhausted",
    icon: SpiralEmoji
  },
  {
    key: "anxious",
    label: "Anxious",
    icon: ScreamEmoji
  },
]

export const DISQUALIFY_REASONS = {
  FULLSCREEN: "fullScreenExit",
  TAB_SWTCH: "tabSwitch"
}

export const PERMISSION_DEVICE_TYPES = {
  CAMERA_MIC: "camera and mic",
  CAMERA: "camera",
  MIC: "mic",
};

export const PERMISSION_ERRORS = {
  VIDEO_SOURCE: "could not start video source",
  NO_DEVICE: "requested device not found",
  PERMISSION_DENIED: "permission denied",
  PERMISSION_DENIED_BY_SYSTEM: "permission denied by system",
  CAMERA_FEED: "camera feed",
  NO_INTERNET: "no internet",
}
