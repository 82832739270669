import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Checkbox } from 'antd';

import GamePlayApis from '../../services/apis/gameplay';

import './style.scss';
import Toast from 'components/dumb/Toast';
import WebcamCapture from 'components/dumb/WebCam/WebCam';
import PermissionsModal from 'components/dumb/PermissionsModal';
import FullscreenModal from './FullscreenModal';
import { Note, Instructions, MicTest } from '../../components/RulesScreen';
import { CompanyLogo } from 'components/common';
import { storage } from 'services/config/storage';
import { updateFCData } from 'services/freshchat';
import { ASSESSMENT_SECTION_ORG_IDS, GODREJ_SECTION_IDS, PERMISSION_ERRORS, PERMISSION_DEVICE_TYPES, POST_WORKSHOP_GODREJ, PRE_WORKSHOP_GODREJ, APNA_ORG_SECTION_IDS } from 'constants/globalConstants';
import ProctoringApi from 'services/apis/proctoring';
import { useIsMobileDevice } from 'hooks';
import { useGameplayConfig } from 'store';
import { classifyMediaDeviceError, getPrivacyPolicyLink } from 'utils/helper';
import AssessmentSections from 'components/RulesScreen/AssessmentSections';
import { GodrejSection } from 'components/RulesScreen/GodrejSection';
import { useTranslation } from 'react-i18next';

const RulesScreen = (props) => {
  /**
   * ===================
   * props from parent
   * ===================
   */

  /**
   * ===================
   * props from redux
   * ===================
   */

  /**
   * ===================
   * constant variables
   * ===================
   */
  const loginType = storage.get.loginType()

  const privacyPolicyLink = getPrivacyPolicyLink();

  const orgId = storage.get.orgId()
  const showAssessmentSection = ASSESSMENT_SECTION_ORG_IDS.includes(orgId);

  /**
   * ===================
   * useStates
   * ===================
   */
  const [apiInProgress, setApiInProgress] = useState(false);
  const [showMobileCamera, setShowMobileCamera] = useState(false);
  const [isMicTestVisible, setIsMicTestVisible] = useState(false)
  // UB-1056: Hardcoded until UI
  const [consent, setConsent] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [fullScreenPermissionVisible, setFullScreenPermissionVisible] = useState(false)
  const [isMediaPermissionChecked, setIsMediaPermissionChecked] = useState(false)
  const [mediaPermissionModalType, setMediaPermissionModalType] = useState("")
  const {
    isVideoProctored,
    isProctored,
    isFullScreen,
    positionId,
    configLanguageSettings
  } = useGameplayConfig()

  const { isRtlLayout, layoutDirection } = configLanguageSettings;

  const { t } = useTranslation();
  /**
   * ===================
   * useEffects
   * ===================
   */
  useEffect(() => {
    const showGameplayOnly = storage.get.showGameplayOnly();
    if (showGameplayOnly) {
      startGame();
      return
    }

    updateFCData(null, isMobile);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (isVideoProctored || isProctored) {
        let failureReason = ""
        try {
          const mediaDevices = await navigator.mediaDevices.enumerateDevices()
          const videoDevices = mediaDevices.filter(({ kind, label }) => kind === 'videoinput' && !label.toLowerCase().includes('back') && !label.toLowerCase().includes('obs'))
          const audioDevices = mediaDevices.filter(({ kind }) => kind === 'audioinput')
          if (isVideoProctored) {
            if (audioDevices.length == 0 || videoDevices.length == 0) {
              failureReason = PERMISSION_ERRORS.NO_DEVICE
            } else {
              const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
              if (stream) failureReason = ""
            }
          } else if (isProctored) {
            if (videoDevices.length == 0) {
              failureReason = PERMISSION_ERRORS.NO_DEVICE
            } else {
              const stream = await navigator.mediaDevices.getUserMedia({ video: true })
              if (stream) failureReason = ""
            }
          }
        } catch (error) {
          failureReason = classifyMediaDeviceError(error?.toString())
        } finally {
          setMediaPermissionModalType(failureReason)
          setIsMediaPermissionChecked(true)
        }
      }
    })()
  }, [isVideoProctored, isProctored])

  /**
   * ===================
   * useRefs
   * ===================
   */

  /**
   * ===================
   * computed variables
   * ===================
   */
  const hasGodrejPosition = GODREJ_SECTION_IDS.includes(positionId);
  const hasApnaOrgPosition = APNA_ORG_SECTION_IDS.includes(positionId);
  const isPostWorkshopPosition = POST_WORKSHOP_GODREJ.includes(positionId)
  const isPreWorkshopPosition = PRE_WORKSHOP_GODREJ.includes(positionId)

  /**
   * ===================
   * custom hooks
   * ===================
   */
  const isMobile = useIsMobileDevice();
  const history = useHistory();
  let { id: linkId } = useParams();

  /**
   * ===================
   * functions
   * ===================
   */
  const startGame = async ({ cameraSupport = null } = {}) => {
    try {
      const showGameplayOnly = storage.get.showGameplayOnly();

      setApiInProgress(true);
      console.log(`[Updaing the game status] for link: `, linkId);
      // UB-1056: Hardcoded until UI
      if (!consent && !showGameplayOnly)
        throw new Error(
          'Consent required (see privacy policy) to start gameplay.'
        );
      await GamePlayApis.updateGamePlayStatus({ consent, cameraSupport, linkId }); // update gameplay status to playing
      console.log(`[Update game status] success`, linkId);
      setApiInProgress(false);
      if (isFullScreen && !isMobile) {
        setFullScreenPermissionVisible(true)
      } else {
        storage.set.linkId(linkId)
        linkId ? history.push(`/games/${linkId}`) : history.push(`/games`)
      }
    } catch (error) {
      Toast('', error?.message || error, 'We are sorry!');
      setApiInProgress(false);
      console.log(error);
    }
  };

  async function confirmUserPhoto({ cameraSupport }) {
    try {
      setApiInProgress(true)
      if (isVideoProctored) {
        const sessionId = storage.get.videoSessionId()
        const response = await ProctoringApi.uploadVideoProctoringImage(sessionId, imgSrc)
        if (response.success) {
          setIsMicTestVisible(true)
        }
      } else if (cameraSupport) {
        const userRefImg = !!storage.get.userRefImg()
        if (!userRefImg) {
          storage.set.userRefImg(imgSrc)
          const response = await ProctoringApi.sendUserSnapShot({
            linkId,
            imgSrc,
            isImageCritical: false,
            criticalReason: '',
          });
          if (response.success) {
            startGame({ cameraSupport });
          }
        } else {
          startGame({ cameraSupport });
        }
      }
    } catch (err) {
      console.error(err)
      setApiInProgress(false)
    }
  }

  const mobileContinueHandler = () => {
    if (isProctored || isVideoProctored) {
      setShowMobileCamera(true);
    } else if (!consent) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    } else {
      startGame();
    }
  };
  /**
   * ===================
   * useCallback
   * ===================
   */

  return isMobile ? (
    <section className="rules-container h-full-page color-primary bg-color-primary flex" dir={layoutDirection}>
      <div className="h-full-fix width-p-50 flex flex-col justify-center items-start p-30">
        <CompanyLogo />
        {showMobileCamera ? (
          <>
            {
              isMicTestVisible
                ? <p
                  style={{
                    lineHeight: '50px',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                  }}
                  className="color-primary font-bold size-30 mt-50 mb-0"
                >
                  One last step!
                </p>
                : <p
                  className="color-primary font-medium opacity-70 size-14 mr-15"
                  style={{ lineHeight: '21px' }}
                >
                  Center your face in the frame and click picture.
                </p>
            }
            {(isProctored || isVideoProctored) && (
              <div className="flex flex-col justify-center items-center mt-10 w-full">
                {
                  isMicTestVisible
                    ? <MicTest onSuccess={() => startGame()} />
                    : isMediaPermissionChecked ? <WebcamCapture
                      initialCapture={true}
                      isMobile={isMobile}
                      imgSrc={imgSrc}
                      setImgSrc={setImgSrc}
                      confirmPhoto={confirmUserPhoto}
                      loading={apiInProgress}
                      consent={consent}
                      setConsent={setConsent}
                      isVideoProctored={isVideoProctored}
                    /> : <></>
                }
              </div>
            )}
          </>
        ) : (
          <>
            {hasGodrejPosition && <GodrejSection isPostWorkshopPosition={isPostWorkshopPosition} isPreWorkshopPosition={isPreWorkshopPosition} />}
            <p className={`color-primary weight-300  mb-20 mt-20 ${hasGodrejPosition ? "size-24" : "size-30"}`}>
              {t("instructions.title")}
            </p>
            <Note 
              visible={isProctored || isVideoProctored}
              hasApnaOrgPosition={hasApnaOrgPosition}
            />
            {showAssessmentSection && <AssessmentSections />}
            <div className="flex flex-col items-start mr-15">
              <Instructions 
                loginType={loginType} 
                showAssessmentSection={showAssessmentSection} 
                hasGodrejPosition={hasGodrejPosition}
                hasApnaOrgPosition={hasApnaOrgPosition}
              />
              {!(isProctored || isVideoProctored) && (
                <div className="flex items-center mb-90">
                  <Checkbox
                    checked={consent}
                    onChange={(e) => setConsent(e.target.checked)}
                    className="mr-10 bottom-10"
                  />
                  <p className="size-18 color-secondary m-0">
                    By checking this box, I agree to the
                    <a
                      className="size-18 font-bold color-primary"
                      href={privacyPolicyLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {' '}
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {!showMobileCamera && (
        <div className="mobile-start-button">
          <Button
            type="primary"
            className="weight-600 size-16 game-start-btn"
            loading={apiInProgress}
            onClick={mobileContinueHandler}
          >
            Continue
          </Button>
        </div>
      )}
      <FullscreenModal
        fullScreenPermissionVisible={fullScreenPermissionVisible}
        setFullScreenPermissionVisible={setFullScreenPermissionVisible}
        isMobile={isMobile}
        linkId={linkId}
        history={history}
      />
      <PermissionsModal
        visible={!!mediaPermissionModalType}
        type={mediaPermissionModalType}
        device={isVideoProctored ? PERMISSION_DEVICE_TYPES.CAMERA_MIC : PERMISSION_DEVICE_TYPES.CAMERA}
      />
    </section>
  ) : (isProctored || isVideoProctored) ? (
    <section className="rules-container height-100 overflowX-hidden color-primary bg-color-primary flex pl-50 pr-50" dir={layoutDirection}>
      <div className="h-full-fix width-p-50 flex flex-col justify-start items-start p-30">
        <CompanyLogo />
        <div className={`flex flex-col justify-center items-start mt-40 left-section`}>
          {hasGodrejPosition && <GodrejSection isPostWorkshopPosition={isPostWorkshopPosition} isPreWorkshopPosition={isPreWorkshopPosition} />}
          <p className={`color-primary weight-500 mb-0 ${hasGodrejPosition ? "size-32 line-height-32" : "line-height-40 size-40"}`}>
            {t("instructions.title")}
          </p>
          <Note 
            visible={isProctored || isVideoProctored} 
            hasApnaOrgPosition={hasApnaOrgPosition}
          />
          {showAssessmentSection && <AssessmentSections />}
          <Instructions 
            loginType={loginType} 
            showAssessmentSection={showAssessmentSection} 
            hasGodrejPosition={hasGodrejPosition}
            hasApnaOrgPosition={hasApnaOrgPosition}
          />
        </div>
      </div>
      <div className={`h-full-fix sticky top-0 flex flex-col justify-center items-center flex-1`}>
        {
          isMicTestVisible
            ? <MicTest onSuccess={() => startGame()} />
            : isMediaPermissionChecked ? <WebcamCapture
              initialCapture={true}
              imgSrc={imgSrc}
              setImgSrc={setImgSrc}
              confirmPhoto={confirmUserPhoto}
              loading={apiInProgress}
              consent={consent}
              setConsent={setConsent}
              isVideoProctored={isVideoProctored}
            /> : <></>
        }
      </div>
      <FullscreenModal
        fullScreenPermissionVisible={fullScreenPermissionVisible}
        setFullScreenPermissionVisible={setFullScreenPermissionVisible}
        isMobile={isMobile}
        linkId={linkId}
        history={history}
      />
      <PermissionsModal
        visible={!!mediaPermissionModalType}
        type={mediaPermissionModalType}
        device={isVideoProctored ? PERMISSION_DEVICE_TYPES.CAMERA_MIC : PERMISSION_DEVICE_TYPES.CAMERA}
      />
    </section>
  ) : (
    <section className="rules-container h-full-page color-primary bg-color-primary flex pl-50 pr-50" dir={layoutDirection}>
      <div className="h-full-fix width-p-50 flex flex-col justify-center items-start p-30">
        <CompanyLogo />
        <p className="greeting color-primary font-bold size-40">
          Welcome aboard! 👋🏻
        </p>
        <p className="color-primary font-light opacity-70 sub-content size-16">
          Hello! As a part of this assessment, we will take you through a series
          of exercises that’ll help us know you better and identify your
          strengths.
        </p>
        <div className="mt-80">
          <div className="flex items-center">
            <Checkbox
              checked={consent}
              onChange={(e) => setConsent(e.target.checked)}
              className="mr-10 relative bottom-10"
            />
            <p className="size-18 color-secondary">
              By checking this box, I agree to the
              <a
                className="size-18 font-bold color-primary"
                href={privacyPolicyLink}
                target="_blank"
              >
                {' '}
                Privacy Policy
              </a>
              .
            </p>
          </div>
          <div className="desktop-start-button">
            <Button
              type="primary"
              className="font-medium game-start-btn"
              loading={apiInProgress}
              onClick={startGame}
              disabled={!consent}
            >
              Let's Go!
            </Button>
          </div>
        </div>
      </div>
      <div className={`h-full-fix width-p-50 flex flex-col items-start p-30 justify-center`}>
        <div className="flex flex-col justify-start items-start p-30">
          {/* <p className="color-primary font-light opacity-70 mt-50 size-16 mb-50">
            Here are some pointers to keep in mind:
          </p> */}
          {showAssessmentSection && <AssessmentSections />}
          <Instructions 
            loginType={loginType} 
            showAssessmentSection={showAssessmentSection} 
            hasGodrejPosition={hasGodrejPosition} 
            hasApnaOrgPosition={hasApnaOrgPosition}
          />
        </div>
      </div>
      <FullscreenModal
        fullScreenPermissionVisible={fullScreenPermissionVisible}
        setFullScreenPermissionVisible={setFullScreenPermissionVisible}
        isMobile={isMobile}
        linkId={linkId}
        history={history}
      />
    </section>
  );
};

export default RulesScreen;
