import { COMPANY_FC_TAG_LIST, DEFAULT_COMPANY_NAME, DEFAULT_FC_TAG_LIST } from "constants/globalConstants";
import { storage } from "services/config/storage";
import { setDocumentDetails } from "utils/helper";

export const initializeFreshChat = (data, mobileView=false) => {
  return;
  let userData = data;
  const inFrame = window.location !== window.parent.location;
  const [companyName] = setDocumentDetails();
  const isDefaultCompany = companyName.toLowerCase().includes(DEFAULT_COMPANY_NAME);
  // When GC loaded in iframe (SDK) or for whitelablled org -> No req. for FC
  if (inFrame || !isDefaultCompany) return;
  
  if (!data) {
    userData = storage.get.fcData();
  }

  if (window.fcWidget?.isInitialized() && userData) {
    return updateFCData(userData);
  } 

  // Tags let us segregate diff views/channels/topics for company
  // If company is present in COMPANY_FC_TAG_LIST, add it as a tag
  // While ignoring default DEFAULT_FC_TAG_LIST
  let tagsList = [];
  const companyTag = storage.get?.orgInfo()?.name?.toLowerCase() ?? "";
  
  if (Object.values(COMPANY_FC_TAG_LIST).includes(companyTag)) 
    tagsList.push(companyTag);
  else 
    tagsList = DEFAULT_FC_TAG_LIST;
  
  if (!userData) {
    window.fcWidget.init({
      token: process.env.REACT_APP_FRESHCHAT_TOKEN,
      host: "https://wchat.in.freshchat.com",
      tags: tagsList,
      config: {
        cssNames: {
          widget: mobileView ? "custom_fc_frame": ""
        }
      }
    });
    // !mobileView -> Do not auto open widget post init/load
    // name -> Corresponds to topics present in fcWidget -> Set in FC dashboard
    // if (!mobileView) window.fcWidget.open({ name: "Contact Us" });
    return;
  }
  const { id, name, email, phone } = userData;
  window.fcWidget.init({
    token: process.env.REACT_APP_FRESHCHAT_TOKEN,
    host: "https://wchat.in.freshchat.com",
    externalId: id,
    tags: tagsList,
    firstName: name,
    email,
    phone,
    meta: {
      userId: id,
    },
    config: {
      cssNames: {
        widget: mobileView ? "custom_fc_frame": ""
      }
    }
  });
  console.log(
    `%c[FC]: Successfully updated user data`,
    "background: yellow; color: red"
  );
  return;
};

export const updateFCData = (data, mobileView=false) => {
  if (data) {
    const { id, name: firstName, email, phone } = data;
    data = {
      id, firstName, email, phone,
      meta: {
        userId: id,
      },
    }
  }
  if (window.fcWidget?.isInitialized()) 
    return window.fcWidget.user
      .update({ ...data })
      .then((res) => {
        console.log(
          `%c[FC]: Successfully updated user data`,
          "background: yellow; color: red"
        );
        return res;
      })
      .catch((err) => {
        console.error(`[FC]: User update fail`, err);
        return err;
      });
  else initializeFreshChat(null, mobileView);
};
