import GamePlayApi from '../../services/apis/gameplay'

export const markGameComplete = async (linkId, payload) => {
    try {
        const response = await GamePlayApi.markGameComplete(linkId, payload)
        return response
    } catch (err) {
        console.log(err)
        return err
    }
}
