import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import configureStore, { GameplayConfigProvider } from './store';
import './utils/i18n';
import 'styles/_main.scss';

const store = configureStore()
ReactDOM.render(
    <Provider store={store}>
        <GameplayConfigProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </GameplayConfigProvider>
    </Provider>,
    document.getElementById('root')
);