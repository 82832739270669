import React, { useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import LoadingScreen from 'views/LoadingScreen';
import NotFound from 'views/NotFound';
import ProtectedRoute from './ProtectedRoute';
import InstructionsModal from 'components/dumb/InstructionsModal';
import SignUp from 'views/SignUp';
import AssessmentEndScreen from 'views/AssessmentEndScreen';
import DeviceTest from 'views/DeviceTest';
import Report from 'views/Report';
import PermissionsModal from 'components/dumb/PermissionsModal';
import { useIsMobileDevice } from '../hooks';
import { storage } from 'services/config/storage';
import { PAUSE_TIMER_EVENT, RESUME_TIMER_EVENT, VIEW_CONSTANTS } from '../constants/helperModalConstants';
import { PROTECTED_ROUTES } from './routes';
import { useGameplayConfig } from 'store';
import { checkStorage, setDocumentDetails } from 'utils/helper';
import { PERMISSION_ERRORS } from 'constants/globalConstants';
import { Unberry } from 'assets/icons';

function AppRouter() {
  const [isPermissionModalVisible, setIsPermissionModalVisible] = useState(false)
  const isMobile = useIsMobileDevice();
  const {
    allowMobileView,
    isLoading: isFetchingConfig,
    fetchConfig
  } = useGameplayConfig();
  const checkStorageAccess = checkStorage();
  const tokenExpired = checkStorageAccess && storage.get.tokenExpiry();
  const beforeUnloadCheck = tokenExpired ? storage.destroy.all() : null;
  const isOrgWhiteLabelled = storage.get.orgInfo()?.organizationConfig?.moduleFlags?.whitelabel

  const handleUserAgentOnlineOffline = () => {
    setIsPermissionModalVisible(!navigator.onLine)
    if (window.location.pathname.includes("/games")) {
      if (navigator.onLine) {
        window.dispatchEvent(new Event(RESUME_TIMER_EVENT))
      } else {
        window.dispatchEvent(new Event(PAUSE_TIMER_EVENT))
      }
    }
  }

  useEffect(() => {
    fetchConfig()
    // Check url for companyName
    // Pass true to allow dom updates
    setDocumentDetails(true);
    window.addEventListener('beforeunload', () => beforeUnloadCheck);
    window.addEventListener("offline", handleUserAgentOnlineOffline)
    window.addEventListener("online", handleUserAgentOnlineOffline)

    return () => {
      window.removeEventListener('beforeunload', () => beforeUnloadCheck);
      window.removeEventListener("offline", handleUserAgentOnlineOffline)
      window.removeEventListener("online", handleUserAgentOnlineOffline)
    }
  }, []);

  if (isFetchingConfig) {
    // showing loader while fetching gameplay config
    return (
      <section className="overflowY-hidden overflowX-hidden h-full-page bg-color-primary flex flex-col justify-center items-center">
        <Spin
          indicator={<LoadingOutlined spin style={{ color: "#fff", fontSize: 28 }} />}
        />
      </section>
    );
  }

  if (!allowMobileView && isMobile && process.env.NODE_ENV !== 'development') {
    // Mobile blocker view
    return (
      <div className="screen-only">
        {
          !isOrgWhiteLabelled
            ? <Unberry />
            : <></>
        }
        <div className="screen-only-message">
          <img
            className="mb-20 width-100"
            src="/assets/icons/change-device.png"
            alt="change-device"
          />
          <div className="flex flex-col justify-center">
            <p className="mb-0 text-center uppercase color-primary align-center">
              We're coming soon on mobile
            </p>
            <p className="text-center uppercase color-primary align-center">
              Use your laptops or desktops to go through {!isOrgWhiteLabelled ? "the Unberry" : "our"}  experience
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {tokenExpired ? (
        <InstructionsModal
          visible={tokenExpired}
          view={VIEW_CONSTANTS.TOKEN_EXPIRY_VIEW}
          onClick={() => window.location.reload()}
        />
      ) : !checkStorageAccess ? (
        <InstructionsModal
          visible={!checkStorageAccess}
          view={VIEW_CONSTANTS.COOKIE_POLICY_VIEW}
          onClick={() => window.location.reload()}
        />
      ) : null}
      <PermissionsModal
        visible={isPermissionModalVisible}
        type={PERMISSION_ERRORS.NO_INTERNET}
      />
      {!(!checkStorageAccess || (checkStorageAccess && tokenExpired)) &&
        (
          <Switch>
            <Route
              path="/system-check"
              render={(props) => <DeviceTest {...props} />}
            />
            <Route
              path="/home/:id"
              render={(props) => <LoadingScreen {...props} />}
            />
            <Route
              path={["/login/:orgName/:id", "/login/:id"]}
              render={(props) => <LoadingScreen {...props} />}
            />
            <Route
              exact
              path="/signup/:orgType/:orgId/:positionId"
              component={SignUp} />
            <Route
              path="/end/:userId/:linkId"
              render={(props) => <AssessmentEndScreen {...props} />}
            />
            <Route
              path="/report/:linkId/:userId"
              render={(props) => <Report {...props} />}
            />
            {PROTECTED_ROUTES.map((route) => (
              <ProtectedRoute
                key={route.path}
                path={route.path}
                component={route.component}
              />
            ))}
            <Route path="*" component={NotFound} />
          </Switch>
        )}
    </>
  );
}

export default withRouter(AppRouter);
