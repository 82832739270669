import React from 'react';

export const GodrejSection = ({ isPostWorkshopPosition = false, isPreWorkshopPosition = false }) => {
	return (
		<div className={`flex flex-col justify-center items-start`}>
			<p className="color-primary weight-400 size-32 mb-24">
				{
					isPreWorkshopPosition ? "Welcome to the Pre Learning Assessment for the Concrete Formwork Masters Workshop"
						: isPostWorkshopPosition ? "Welcome to the Post Learning Assessment for the Concrete Formwork Masters Workshop"
							: "Welcome to the Knowledge Assessment!"
				}
			</p>
			<p className="color-primary weight-500 size-24 mb-4">
				Purpose
			</p>
			<p className="color-primary weight-400 opacity-70 size-16 mb-24">
				{
					isPreWorkshopPosition ? `The “Concrete Formwork Masters” workshop, powered by GPL Alchemy, Operations Academy, aims to develop deep expertise in the core technical trades and enhance capabilities on concreting and formwork concepts. The pre-learning assessment is done to understand current level of knowledge and will help improve the training content and delivery.`
						: isPostWorkshopPosition ? "To understand your progress on learning absorption, the following assessment will help us dive deep into making our workshop better."
							: "To understand your progress in learning the concepts explained during the workshop Sequence Essentials."
				}
			</p>
			<p className="color-primary weight-500 size-24 mb-4">
				Test Format
			</p>
			<p className="color-primary weight-400 opacity-70 size-16 mb-32">
				{
					isPreWorkshopPosition ? `The Assessment consists of 40 Multiple Choice Questions (Single Option Correct) and you will get 60 minutes to complete the assessment.`
						: isPostWorkshopPosition ? "The Assessment consists of 40 Multiple Choice Questions (Single Option Correct) and you will get 60 minutes to complete the assessment."
							: "To understand your progress in learning the concepts explained during the workshop Sequence Essentials."
				}
			</p>
			<p className="color-primary weight-500 size-24 mb-24 ">
				All the best!
			</p>
		</div>
	);
};
