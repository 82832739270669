import React, { useEffect, useState } from 'react';
import { Button, Progress, Tooltip } from 'antd';
import './style.scss';
import { disableFullScreen } from 'utils/helper';
import { useIsMobileDevice } from 'hooks';
import Webcam from 'react-webcam';

const GlobalHeader = (props) => {
    const isMobile = useIsMobileDevice();
    const [webcamKey, setWebcamKey] = useState(0);
    const [deviceId, setDeviceId] = useState(0);
    const [webcamTooltip, setWebcamTooltip] = useState(true);

    useEffect(() => {
        setTimeout(() => setWebcamTooltip(false), 5000);
    }, []);


    return (
        <header className="global-header width-p-100 flex justify-between items-center">
            <section className={`games ${isMobile ? `${props.isProctored ? 'mobile-proc' : 'mobile'}` : ""}`}>
                <span className="color-primary weight-600 size-16 mr-20 content">
                    {props.currentGameIndex + 1}/{props.totalAvailableGames?.length}
                </span>
                <span className="color-primary weight-600 size-16 mr-20 content game-name">
                    {props.currentLoadedGame}
                </span>
            </section>
            <section className="video-stream-area">
            {
                props.isProctored &&
                <>
                    <Webcam
                        key={webcamKey}
                        muted={false}
                        mirrored
                        onError={(error) => {
                            console.error('Error in webcam: ', error);
                        }}
                        style={{
                            position: 'absolute',
                            right: isMobile ? "72px": "180px",
                            top: isMobile ? "4px": "1px",
                            zIndex: 1,
                            borderRadius: isMobile ? '50%': "50%",
                            width: isMobile ? '50px' : '58px',
                            height: isMobile ? '50px' : '58px',
                        }}
                        videoConstraints={{
                            width: 120,
                            height: 120,
                            facingMode: 'user',
                            deviceId: deviceId,
                        }}
                    />
                    {
                        isMobile &&
                        <Tooltip 
                            title={"Your face should be clearly visible in this frame at all times"}
                            placement="bottom"
                            open={webcamTooltip}
                            zIndex={2000}
                            overlayClassName={"webcam-tooltip"}
                        >
                        </Tooltip>
                    }
                </>
            }
            </section>
            <section className="assessment-center-cta flex items-center">
                {
                    !isMobile ?
                        <div
                            style={{
                                width: "100px"
                            }}
                            className="flex flex-col items-start"
                        >
                            <span className="color-primary weight-600 size-14">
                                {props.currentGameIndex}/{props.totalAvailableGames?.length} Completed
                            </span>
                            <Progress
                                percent={Math.floor(((props.currentGameIndex) / (props.totalAvailableGames?.length)) * 100)}
                                size="small"
                                showInfo={false}
                                strokeColor={"#FFF"}
                            />
                        </div> : <></>
                }
                {
                    props.isFullScreen && !isMobile && props.isFullScreenEnabled
                        ? <Button
                            type="primary"
                            className="flex items-center font-medium br-20 exit-fullscreen-btn ml-20"
                            onClick={disableFullScreen}
                        >
                            Exit FullScreen
                        </Button>
                        : <></>
                }
            </section>
            <div className="global-header-gradient absolute top-0" />
        </header>
    )
}

export default GlobalHeader;
