// TODO: @Vineet make separate API file and helper file

import { storage } from 'services/config/storage';
import GamePlayApis from 'services/apis/gameplay';
import Toast from 'components/dumb/Toast';
import { CODE, INTERNET_STATUS } from './consts';
import axios from 'axios';
import ProctoringApi from 'services/apis/proctoring';
import { DEFAULT_COMPANY_NAME, PERMISSION_ERRORS } from 'constants/globalConstants';
import moment from 'moment';

export const getToastDescription = (message) => message?.toString()?.toLowerCase()?.includes("failed to fetch")
  ? "There is an unexpected error, please make sure you are on a stable internet connection and try again."
  : message

export const getAllowedOrigins = () => {
  const games = storage.get.listOfGames();
  if (games) {
    return games.map((game) => game.url);
  } else return [];
};

/**
 * get list of games for a linkId
 * @param {UUID} linkId
 * returns {Array of games which have url}
 */
export const getListOfGames = async (linkId) => {
  try {
    const response = await GamePlayApis.getListOfGames(linkId);
    const games = response?.games
      ?.map((g) => ({
        id: g.id,
        name: g?.name || g?.title,
        mediaUrl: g.mediaUrl,
        url:
          process.env.REACT_APP_ENV === 'poc'
            ? g.deeplink.replace('-uat', '-poc')
            : g.deeplink,
        status: g?.status,
      }))
      ?.filter((g) => !!g.url);
    return games;
  } catch (error) {
    console.error(error);
    Toast('error', error?.message);
  }
};

export const getGameLevelData = async (gameId, linkId) => {
  try {
    const response = await GamePlayApis.getGameLevelData(gameId, linkId);
    if (response?.success) {
      return response?.game?.gameData;
    }
  } catch (error) {
    console.error(error);
    Toast('error', error?.message);
  }
};

export const saveGameLevelData = async (linkId, gameData) => {
  const { gameId, gameName, gameLevelData } = gameData;
  try {
    const response = await GamePlayApis.saveGameLevelData(
      gameId,
      linkId,
      gameLevelData
    );
    if (response && response.success) {
      console.log(`Save data success for ${gameName}`);
      return true;
    } else {
      // Checks for token invalidity/expiry here
      return false;
    }
  } catch (error) {
    console.error(`Save data error for ${gameName}`);
    // Checks for token invalidity/expiry here
    return false;
  }
};

export const saveTabSwitchCount = async (linkId, count) => {
  try {
    const response = await ProctoringApi.saveTabSwitchCount(linkId, {
      noOfTimes: count,
    });
    if (response && response.success) {
      return true;
    } else {
      // Checks for token invalidity/expiry here
      return false;
    }
  } catch (error) {
    console.error(`save tab switch count error`);
    // Checks for token invalidity/expiry here
    return false;
  }
};

/**
 * checks if the internet is low
 * @param {}
 * @returns {Boolean} true if the internet is low
 */
export const checkIsInternetLow = async () => {
  const startTime = new Date().getTime();
  try {
    await axios.get(INTERNET_STATUS.FILE_URL, {
      headers: {
        'Cache-Control': 'no-store',
      },
    });
    const endTime = new Date().getTime();
    const timeTaken = (endTime - startTime) / 1000;
    return timeTaken > 2;
  } catch (e) {
    console.log(e.message);
  }
};

export const checkStorage = () => {
  try {
    window.localStorage.setItem('ex-fg', 'check');
    if (window.localStorage.getItem('ex-fg')) return 'localStorage';
    return 'sessionStorage';
  } catch {
    return null;
  }
};

export const getQuizData = async (quizId, linkId) => {
  try {
    const response = await GamePlayApis.getQuizData(quizId, linkId);
    const { success, quiz, ...rest } = response;
    if (success) {
      return { ...quiz, ...rest };
    }
  } catch (error) {
    console.error(error);
    Toast('error', error?.message);
  }
};

export const getChatMessage = async ({ promptId, messages, id = "" }) => {
  try {
    // getting chat message reply
    const chatResponse = await GamePlayApis.completeChat(promptId, {
      messages,
    });
    if (!chatResponse?.success) {
      throw chatResponse;
    }

    // appending the chat message as type assistant to send back
    // appending question id for multi-chat quiz.
    return [...messages, { type: 'assistant', content: chatResponse.data, ...(id && { id }) }];
  } catch (err) {
    console.error('Error in chat response api: ', err);
    return err;
  }
};

export const uploadAudioAnswer = async ({ linkId, questionId, mediaFile }) => {
  const errorMessage = "Audio upload failed. Please record and upload again.";
  try {
    if (mediaFile) {
      const mediaUploadResponse = await GamePlayApis.uploadAudioAnswer(
        linkId,
        questionId,
        mediaFile
      );
      if (!mediaUploadResponse.success) {
        throw new Error(mediaUploadResponse?.message ?? errorMessage);
      }
      return mediaUploadResponse?.media?.id;
    } else {
      throw new Error(errorMessage);
    }
  } catch (error) {
    console.error(error);
    Toast('error', error.message);
    return null;
  }
};

export const saveQuizAnswer = async (
  linkId,
  { quizId, questionId,
    // mediaFile, 
    // mediaId,
    messages, promptId, ...payload }
) => {
  try {
    // Comment for time being

    // uploading audio file in case of audio recorder question
    // if (mediaFile) {
    //   const mediaUploadResponse = await GamePlayApis.uploadAudioAnswer(
    //     linkId,
    //     questionId,
    //     mediaFile
    //   );
    //   if (mediaUploadResponse.success) {
    //     payload.mediaId = mediaUploadResponse?.media?.id;
    //   } else {
    //     console.error(mediaUploadResponse);
    //     throw new Error(mediaUploadResponse?.message ?? 'Audio upload failed');
    //   }
    // }

    const response = await GamePlayApis.submitQuizAnswer(
      linkId,
      quizId,
      questionId,
      payload
    );
    if (response && response.success) {
      console.log(`Save data success for Quiz ${quizId}`);

      return true;
    } else {
      // Checks for token invalidity/expiry here
      return false;
    }
  } catch (error) {
    console.error(`Save data error for Quiz ${quizId}`);
    // Checks for token invalidity/expiry here
    return false;
  }
};

export const markQuizComplete = async (quizId, { linkId, timeSpent }) => {
  try {
    const response = await GamePlayApis.markQuizComplete(quizId, {
      linkId,
      timeSpent,
    });
    return response;
  } catch (error) {
    console.error('Error in marking quiz complete:');
    console.error(error);
    return error;
  }
};

export const getCodingSession = async (problemId, linkId) => {
  try {
    const response = await GamePlayApis.getCodingSession(problemId, linkId);
    if (response.success) {
      const { code, startTime, duration, languageId } = response.data;
      const gameSessionData = {
        code,
        startTime,
        duration,
        languageId,
      };
      return gameSessionData;
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveCodingSession = async (payload) => {
  try {
    const response = await GamePlayApis.saveCodingSession(payload);
    const { success, data } = response;
    if (success) {
      return true;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getCodingProblemData = async (problemId, linkId) => {
  try {
    const response = await GamePlayApis.getCodingProblem(problemId, linkId);
    const { success, data } = response;
    if (success) {
      return { data };
    }
  } catch (error) {
    console.error(error);
  }
};

export const saveCodingAnswer = async ({
  gameLevelData,
  problemId,
  linkId,
  saveDataType,
}) => {
  const { CODE_SUBMIT } = CODE;
  try {
    let payload = {
      ...gameLevelData,
      problemId,
    };
    const checkCodeSubmit = saveDataType === CODE_SUBMIT;
    let response = {};
    if (checkCodeSubmit) {
      payload = { ...payload, linkId };
      response = await GamePlayApis.submitCodingAnswer(payload);
    } else {
      response = await GamePlayApis.compileCodingAnswer(payload);
    }
    if (response?.success) {
      // const checkCodeStatus = checkCodeSubmit ? 'submitted' : 'compiled';
      // Toast('success', `Code ${checkCodeStatus} successfully.`);
      return response;
    }
  } catch (error) {
    console.error(error);
  }
};

export const createGameplayModuleMasterSession = async (payload) => {
  try {
    const response = await GamePlayApis.createGameplayModuleMasterSession(payload);
    if (response?.success) {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get formData object based on the data provided
 * @param {object} data object to be converted to formData
 * @return {object: FormData}
 */
export const objectToFormData = (data) => {
  const formData = new FormData();

  for (let key in data) {
    formData.append(key, data[key]);
  }

  return formData;
};

export const getImgByteArrays = (byteCharacters) => {
  const byteArrays = [];
  const sliceSize = 512;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  return byteArrays;
};

export const getMediaStream = async (includeAudio = true) => {
  try {
    const mediaDevices = await navigator.mediaDevices.enumerateDevices()
    const videoSources = mediaDevices.filter(({ kind, label }) => kind === 'videoinput' && !label.toLowerCase().includes('back'))
    if (videoSources.length == 0) {
      return { isAllowed: true, mediaDevices }
    }
    else {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: includeAudio });
      // do something with the media stream, e.g. show it in a video element
      if (stream) return { isAllowed: true, mediaDevices };
    }
  } catch (error) {
    console.error('Failed to get user media', error);
    // handle the error, e.g. show an error message to the user
    return { isAllowed: false, failureReason: error.toString() };
  }
};

export function enableFullScreen({ redirectToGames = () => { } }) {
  const element = document.documentElement

  if (element?.requestFullscreen) {
    element?.requestFullscreen()?.then(() => { redirectToGames() }).catch(err => {
      console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (element?.mozRequestFullScreen) {
    element?.mozRequestFullScreen()?.then(() => { redirectToGames() }).catch(err => {
      console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (element?.webkitRequestFullscreen) {
    element?.webkitRequestFullscreen()?.then(() => { redirectToGames() }).catch(err => {
      console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (element?.msRequestFullscreen) {
    element?.msRequestFullscreen()?.then(() => { redirectToGames() }).catch(err => {
      console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (element?.webkitEnterFullscreen) {
    element?.webkitEnterFullscreen()?.then(() => { redirectToGames() }).catch(err => {
      console.error(`An error occurred while trying to switch into fullscreen mode: ${err.message} (${err.name})`)
    })
  }
}

export function disableFullScreen() {
  if (document?.exitFullscreen) {
    document?.exitFullscreen()?.then(() => { }).catch(err => {
      console.error(`An error occurred while exiting from fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (document?.mozCancelFullScreen) {
    document?.mozCancelFullScreen()?.then(() => { }).catch(err => {
      console.error(`An error occurred while exiting from fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (document?.webkitExitFullscreen) {
    document?.webkitExitFullscreen()?.then(() => { }).catch(err => {
      console.error(`An error occurred while exiting from fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (document?.msExitFullscreen) {
    document?.msExitFullscreen()?.then(() => { }).catch(err => {
      console.error(`An error occurred while exiting from fullscreen mode: ${err.message} (${err.name})`)
    })
  } else if (document?.webkitExitFullscreen) {
    document?.webkitExitFullscreen()?.then(() => { }).catch(err => {
      console.error(`An error occurred while exiting from fullscreen mode: ${err.message} (${err.name})`)
    })
  }
}

/**
 * This function creates and returns the image html element.
 * @param {src} - image source
 * @return {imgHTMLElement} - image html element
 */
export const createImgHTML = (src) => {
  const imgHTMLElement = document.createElement('img');
  imgHTMLElement.src = src;
  imgHTMLElement.style.height = '30px';
  imgHTMLElement.style.width = '50px';
  return imgHTMLElement;
}

/**
 * This function creates and returns the image data from img html element
 * @param {imgHTMLElement} - image html element
 * @return {imageData} - image data
 */
export const getImageData = (imgHTMLElement) => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const aspectRatio = imgHTMLElement.width / imgHTMLElement.height
  const maxWidth = canvas.width
  const maxHeight = canvas.height

  let drawWidth = maxWidth
  let drawHeight = maxWidth / aspectRatio

  if (drawHeight > maxHeight) {
    drawHeight = maxHeight
    drawWidth = maxHeight * aspectRatio;
  }

  const drawX = (canvas.width - drawWidth) / 2;
  const drawY = (canvas.height - drawHeight) / 2;
  context.drawImage(imgHTMLElement, drawX, drawY, drawWidth, drawHeight);
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
  return imageData

}

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Used to change on document title, fav_icon 
 * When url does not contain DEFAULT_COMPANY_NAME
 */

export const setDocumentDetails = (allowHTMLUpdate = false) => {

  const location = window.location;
  const windowUrl = location.href;

  if (windowUrl.includes(DEFAULT_COMPANY_NAME) || windowUrl.includes("localhost"))
    return [DEFAULT_COMPANY_NAME];

  /*** Only for testing  ***/
  // const placeholderUrl = "https://dashboard.kaguya-sama.aizen.com";
  // const urlArray = placeholderUrl.split(".");

  const urlArray = windowUrl.split(".");
  const companyName = urlArray[urlArray.length - 2];

  if (allowHTMLUpdate) {
    // Update title
    document.title = `${capitalizeFirstLetter(companyName)}`;

    // Update fav_icon
    const existingFavicon = document.querySelector('link[rel="icon"]');
    if (existingFavicon) {
      const faviconPath = `favicon_${companyName.toLowerCase().split("-").join("")}.ico`;
      existingFavicon.href = faviconPath;
    }
  }

  return [companyName];
};

export const getPrivacyPolicyLink = () => {
  return window.location.href.toLowerCase().includes(DEFAULT_COMPANY_NAME) ||
    window.location.href.toLowerCase().includes("localhost") ?
    "https://unberry.com/privacy-policy" :
    "https://www.foundit.in/info/privacy";
}

export const validatePercentage = (_, value) => {
  const percentageRegex = /^(?:\d+(?:\.\d+)?|\.\d+)%?$/
  if (!value || percentageRegex.test(value)) {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Please enter valid percentage'))
}

export const validateYear = (_, value) => {
  const yearRegex = /^\d{4}$/
  const currentYear = Number(new Date().getFullYear())
  if (!value || yearRegex.test(value)) {  //&& Number(value) <= currentYear
    return Promise.resolve()
  }
  return Promise.reject(new Error('Please enter a valid year'))
}

/**
* @param {years} - Number of years before the current date.
* @return {resultDate} - Date
*/
export const getDateBeforeNYears = (years) => {
  const today = new Date()
  const resultDate = new Date(today.getFullYear() - years, today.getMonth(), today.getDate())
  return resultDate
}

/**
 *
 * @param {string} value
 * @param {string} format
 * @returns {string} formatted date
 */
export const getDefaultFormattedDate = (value, format = "Do MMM YYYY") => {
  if (!value) return ""

  const dateObject = moment(value)

  if (dateObject.isValid()) return dateObject.format(format)
  else return ""
};

export const classifyMediaDeviceError = (error = "") => {
  let classifiedError = ""
  if (error?.toLowerCase()?.includes(PERMISSION_ERRORS.VIDEO_SOURCE)) {
    classifiedError = PERMISSION_ERRORS.VIDEO_SOURCE
  } else if (error?.toLowerCase()?.includes(PERMISSION_ERRORS.NO_DEVICE)) {
    classifiedError = PERMISSION_ERRORS.NO_DEVICE
  } else if (error?.toLowerCase()?.includes(PERMISSION_ERRORS.PERMISSION_DENIED_BY_SYSTEM)) {
    classifiedError = PERMISSION_ERRORS.PERMISSION_DENIED_BY_SYSTEM
  } else if (error?.toLowerCase()?.includes(PERMISSION_ERRORS.PERMISSION_DENIED)) {
    classifiedError = PERMISSION_ERRORS.PERMISSION_DENIED
  } else if (error?.toLowerCase()?.includes(PERMISSION_ERRORS.CAMERA_FEED)) {
    classifiedError = PERMISSION_ERRORS.CAMERA_FEED
  } else {
    classifiedError = error
  }
  return classifiedError
}