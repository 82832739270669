import moment from "moment";
import development from "services/config/apiUrl";
import { Api } from "services/config/request"
import { getImgByteArrays } from "utils/helper";

const PROCTORING_API_URL = development.PROCTORING_API_URL;
const NO_BASE_URL = { noBaseUrl: true };

const processImgToBlob = (imgSrc, id) => {
    const currentTime = new Date();
    imgSrc = imgSrc.replace('data:image/jpeg;base64,', '');

    const byteCharacters = window.atob(imgSrc);
    const blob = new Blob(getImgByteArrays(byteCharacters), {
        type: 'image/jpeg',
    });

    const fileName = `${id}|${moment(currentTime).format(
        'DD-MM-YYYY'
    )}|${moment(currentTime).format('HH:mm:ss')}.jpeg`;
    return [blob, fileName];
};

const ProctoringApi = {
    getUserReferenceImg(linkId) {
        return Api.get(`/api/proctoring/v1/ref-image?linkId=${linkId}`)
    },
    sendUserSnapShot({ linkId, imgSrc, isImageCritical, criticalReason = '' }) {
        const [blob, fileName] = processImgToBlob(imgSrc, linkId);
        const file = new File([blob], fileName, { type: blob.type })

        const payload = {
            linkId,
            file,
            critical: isImageCritical,
            ...(criticalReason && { criticalReason }),
        }

        return Api.post('/api/proctoring/v1', payload, {
            isFormData: true,
        })
    },
    saveTabSwitchCount(linkId, countData) {
        return Api.post(`/api/session/v1/${linkId}/tab-switches`, countData)
    },
    sendUserVideo({ file, fileName, startTime, endTime, duration, sessionId, type }) {
        const payload = {
            fileName,
            type,
            startTime,
            endTime,
            duration,
            file,
        }
        return Api.post(`${PROCTORING_API_URL}/api/media/v1/${sessionId}/save-video`, payload, {
            isFormData: true,
            noBaseUrl: true
        })
    },
    getVideoSessionId(linkId) {
        return Api.post(`${PROCTORING_API_URL}/api/session/v1/${linkId}/create-session`, {}, {
            noBaseUrl: true
        })
    },
    uploadVideoProctoringImage(sessionId, imgSrc) {
        const [blob, fileName] = processImgToBlob(imgSrc, sessionId);
        const file = new File([blob], fileName, { type: blob.type })

        const payload = { file };
        return Api.post(`${PROCTORING_API_URL}/api/session/v1/${sessionId}/proctoring-image`, payload, {
            isFormData: true,
            noBaseUrl: true
        })
    },
    markVideoProctoringComplete(linkId) {
        return Api.post(`${PROCTORING_API_URL}/api/proctoring/v1/${linkId}/complete`, {}, {
            noBaseUrl: true
        })
    },
}

export default ProctoringApi