import React from 'react';
import { useTranslation } from 'react-i18next';

const apnaOrgInstructions = [
  "The assessment will be monitored throughout the duration of the test and video recordings and images will be captured.",
  "Do not switch tabs or minimize the assessment window; doing so will lead to disqualification.",
  "Do not speak with anyone or use any other device during the test."
];


export const Note = ({ visible, hasApnaOrgPosition }) => {
  const { t } = useTranslation();

  return visible ? (
    <div className="note-div p-16 br-10 mt-20 mb-20">
      <h3 className="">{t('note.important_note')}</h3>
      <ol>
        {
          hasApnaOrgPosition ?
            <>
              {
                apnaOrgInstructions.map((point, index) => (
                  <li
                    key={index}
                    className="size-16 font-light opacity-70 color-primary"
                  >
                    {point}
                  </li>
                ))
              }
            </> :
            <>
              {t('note.note_points', { returnObjects: true }).map((point, index) => (
                <li
                  key={index}
                  className="size-16 font-light opacity-70 color-primary"
                >
                  {point}
                </li>
              ))}
            </>
        }

      </ol>
    </div>
  ) : null;
};