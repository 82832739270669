import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-converter';
import '@tensorflow/tfjs-backend-webgl';
import * as blazeface from '@tensorflow-models/blazeface';
import { createImgHTML } from './helper';
import i18n from './i18n'; // Import the i18n instance directly

export const detectFacesInImage = async (imgSrc) => {
  let isImageCritical = false;
  let criticalReason = '';

  try {
    const currentImg = createImgHTML(imgSrc);
    const model = await blazeface.load();
    const faceDescriptions = await model.estimateFaces(currentImg, false);

    if (faceDescriptions.length === 0) {
      // No face detected mark as critical
      isImageCritical = true;
      criticalReason = i18n.t('detectFacesInImage.no_face_detected');
    } else if (faceDescriptions.length > 1) {
      // More than one face detected mark as critical
      isImageCritical = true;
      criticalReason = i18n.t('detectFacesInImage.multiple_faces_detected');
    } else if (faceDescriptions.length === 1) {
      // if face captured is partial or at an angle
      if (faceDescriptions[0].probability[0] < 0.9) {
        isImageCritical = true;
        criticalReason = i18n.t('detectFacesInImage.no_person_detected');
      }
    }
  } catch (e) {
    console.log(e);
  } finally {
    return {
      isImageCritical,
      criticalReason,
    };
  }
};
