import React from 'react';
import { useTranslation } from 'react-i18next';
import { useGameplayConfig } from 'store';
import ApnaOrgInstructions from './ApnaOrgSection';

const ShowAssessmentSectionInstructions = () => {
  const { t } = useTranslation();

  return (
    <ul className="color-primary font-medium pl-0">
      {t('instructions.assessment_section', { returnObjects: true }).map((point, index) => (
        <li key={index} className="flex flex-col ml-0 mb-14">
          <span className="size-18 font-light color-primary">{point}</span>
        </li>
      ))}
    </ul>
  );
};

export const Instructions = ({ loginType, showAssessmentSection, hasGodrejPosition, hasApnaOrgPosition }) => {

  const { t } = useTranslation();
  const { configLanguageSettings: { isRtlLayout } } = useGameplayConfig()

  if (showAssessmentSection) return <ShowAssessmentSectionInstructions />;
  if (hasApnaOrgPosition) return <ApnaOrgInstructions />;

  return (
    <ul className={`color-primary font-medium rules-list pl-0 ${isRtlLayout ? "pr-0" : ''}`}>
      {loginType === 'THIRD_PARTY' && (
        <li className="flex flex-col ml-0 mb-20">
          <span className="size-20 font-medium color-primary">{t('instructions.third_party.how_long')}</span>
          <span className="size-14 font-light opacity-70 color-primary">{t('instructions.third_party.duration')}</span>
        </li>
      )}
      <li className="flex flex-col ml-0 mb-20">
        <span className="size-20 font-medium color-primary">
          {t(
            loginType === 'THIRD_PARTY'
              ? 'instructions.third_party.essentials_title'
              : 'instructions.default.be_your_best_title'
          )}
        </span>
        <span className="size-14 font-light opacity-70 color-primary">
          {t(
            loginType === 'THIRD_PARTY'
              ? 'instructions.third_party.essentials_text'
              : 'instructions.default.be_your_best_text'
          )}
        </span>
      </li>
      <li className="flex flex-col ml-0 mb-20">
        <span className="size-20 font-medium color-primary">
          {t(
            loginType === 'THIRD_PARTY'
              ? 'instructions.third_party.stay_focused_title'
              : 'instructions.default.essentials_title'
          )}
        </span>
        <span className="size-14 font-light opacity-70 color-primary">
          {t(
            loginType === 'THIRD_PARTY'
              ? 'instructions.third_party.stay_focused_text'
              : 'instructions.default.essentials_text'
          )}
        </span>
      </li>
      {loginType !== 'THIRD_PARTY' && (
        <li className="flex flex-col ml-0 mb-20">
          <span className="size-20 font-medium color-primary">{t('instructions.default.manage_time_title')}</span>
          <span className="size-14 font-light opacity-70 color-primary">
            {hasGodrejPosition
              ? t('instructions.default.godrej_manage_time_text')
              : t('instructions.default.manage_time_text')}
          </span>
        </li>
      )}
      <li className="flex flex-col ml-0 mb-20">
        <span className="size-20 font-medium color-primary">
          {t(
            loginType === 'THIRD_PARTY'
              ? 'instructions.third_party.results_title'
              : 'instructions.default.stay_focused_title'
          )}
        </span>
        <span className="size-14 font-light opacity-70 color-primary">
          {t(
            loginType === 'THIRD_PARTY'
              ? 'instructions.third_party.results_text'
              : 'instructions.default.stay_focused_text'
          )}
        </span>
        {loginType === 'THIRD_PARTY' && (
          <span className="size-12 font-light opacity-50 font-italic color-primary">
            {t('instructions.third_party.results_note')}
          </span>
        )}
      </li>
    </ul>
  );
};
